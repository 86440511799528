import React, { useState, useContext } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import "@styles/Faqs.css";
import { IpContext } from "@components/contexts/IpContext";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import SmallBanner from "@components/common/SmallBanner";
import { Link } from "gatsby";

export default function PreguntasFrecuentes(props) {
    return (
        <>
            <Layout>
                <Seo
                    title="Preguntas frecuentes"
                    description="Todas tus dudas acerca de nuestros productos"
                    tags="ayuda,faq,faqs,preguntas,frecuentes"
                />
                <SmallBanner
                    title="Preguntas frecuentes"
                    subtitle="Todas tus dudas en un único lugar."
                    filename="bgs/bgFaq"
                />

                <div className={`faqs ${props.className}`}>
                    <div className="container lg:px-0 py-20">
                        <Title>Aplicaciones</Title>
                        <Text className="text-justify">
                            ¿Tienes alguna duda acerca de nuestra aplicaciones? A continuación te
                            mostramos las preguntas más recurrentes solicitadas por nuestros
                            usuarios. En caso que no encuentres la tuya puedes contactarnos a través
                            de nuestra sección de{" "}
                            <Link to="/contacto#form" className="default-link">
                                Contacto
                            </Link>{" "}
                            y seleccionar como asunto el nombre de la app sobre la que quieres
                            preguntar.
                        </Text>

                        <div className="grid grid-cols-1 gap-5 mt-10 mb-10">
                            <Faq title="¿Necesito estar registrado para acceder a la aplicación?">
                                Sí. Para poder acceder a la aplicación y que podamos guardar tu
                                información de forma correcta tienes que autentificarte en la
                                aplicación mediante alguna cuenta de correo (de momento sólo
                                disponible Gmail). En breve ofreceremos otros métodos alternativos
                                de autenticación.
                            </Faq>

                            <Faq title="¿Puedo utilizar la aplicación en más de un dispositivo?">
                                Sí, puedes hacerlo sin problemas. Solo tendrás que identificarte con
                                tu cuenta de Gmail o Apple, y tendrás todos tus datos sincronizados
                                en el otro terminal.
                                <br />
                                <br />
                                <strong>Aviso:</strong> no está permitido compartir cuentas entre
                                usuarios. Además,{" "}
                                <strong>
                                    no se permite el uso de la cuenta en más de dos dispositivos al
                                    mismo tiempo
                                </strong>
                                . El uso indebido de tu cuenta podrá conllevar el{" "}
                                <strong>bloqueo permanente</strong> del acceso a la aplicación.
                            </Faq>

                            <Faq title="¿Cómo puedo conseguir los módulos?" hidden={true}>
                                Los módulos deben adquirirse a través de la aplicación y el pago se
                                debe realizar mediante Paypal. Aviso: la duración de la suscripción
                                de cada módulo es de 1 año a partir de la fecha de activación.
                            </Faq>

                            <Faq title="¿Expiran las suscripciones a los módulos?" hidden={true}>
                                Sí. La suscripción de los módulos caduca al año de ser contratados,
                                a no ser que la suscripción sea por otro intérvalo de tiempo.
                            </Faq>

                            <Faq
                                title="¿Puedo renovar un módulo antes de que caduque?"
                                hidden={true}
                            >
                                Sí. En el menú lateral Tienda (de la aplicación) podrás renovar
                                cualquier suscripción a un módulo por un año adicional tantas veces
                                como desee.
                            </Faq>

                            <Faq
                                title="¿Perderé días de suscripción si renuevo un módulo antes de que haya expirado o caducado?"
                                hidden={true}
                            >
                                No. Cuando renuevas un módulo se añaden 365 días más a los que te
                                quedaban por finalizar la suscripción.
                            </Faq>

                            <Faq title="¿Cómo puedo impugnar una pregunta de la aplicación que pienso que tiene un error?">
                                Hemos añadido una opción para impugnar preguntas. Para impugnar una
                                pregunta solo has de ir al menú Histórico, marcar un examen y hacer
                                clic encima de una pregunta y se automatizará el proceso haciéndonos
                                llegar un correo con la pregunta incorrecta. Si lo prefieres puedes
                                mandarnos una captura de pantalla con las preguntas a la siguiente
                                dirección de correo{" "}
                                <a href="mailto:info@iopos.academy" className="link">
                                    info@iopos.academy
                                </a>{" "}
                                indicándonos el error.
                            </Faq>

                            <Faq
                                title="Puedo pagar una suscripción de otro modo que no sea Paypal"
                                hidden={true}
                            >
                                No. Actualmente la aplicación sólo soporta este método de pago. Para
                                más información puedes mandarnos un correo a{" "}
                                <a href="mailto:info@iopos.academy" className="link">
                                    info@iopos.academy
                                </a>
                                .
                            </Faq>

                            <Faq title="¡No me deja acceder a la aplicación!">
                                ¡Cálmate y no te preocupes! Cualquier problema que tengas con la
                                aplicación no dudes en contárnoslo a través del correo{" "}
                                <a href="mailto:info@iopos.academy" className="link">
                                    info@iopos.academy
                                </a>
                                . ¡Estaremos encantados de ayudarte!
                            </Faq>

                            <Faq title="¿Puedo devolver un módulo adquirido?" hidden={true}>
                                <div>¡Sí!</div>

                                <div className="mt-2">
                                    <h1 className="font-semibold">Módulos de Test:</h1>
                                    <div>
                                        Una vez adquirido el módulo dispones de 14 días para
                                        realizar un cambio o para su devolución y posterior
                                        reembolso, siempre y cuando no se hayan realizado más de 15
                                        test en dicho módulo.
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <h1 className="font-semibold">Módulos de Podcast:</h1>
                                    <div>
                                        Una vez adquirido el módulo dispones de 5 días para realizar
                                        un cambio o para su devolución y posterior reembolso.
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <strong>¡Atención!</strong> No se aceptará en ningún caso la
                                    compra de un módulo en oferta si previamente se ha solicitado la
                                    devolución del mismo. Además,{" "}
                                    <strong>no se realizarán devoluciones</strong> si el módulo ha
                                    sido <strong>bloqueado por uso fraudulento o indebido</strong>.
                                    Para más información, contacta con nosotros a través del correo:{" "}
                                    <a href="mailto:info@iopos.academy" className="link">
                                        info@iopos.academy
                                    </a>
                                </div>
                            </Faq>

                            <Faq
                                title="¿Cuánto tardan en hacerse efectivas las devoluciones?"
                                hidden={true}
                            >
                                Las devoluciones se harán mediante el mismo método de pago, por lo
                                qué será Paypal el que abone el reembolso en su cuenta (entre 6 y 10
                                días laborables). Para más información consulta la página de ayuda
                                de Paypal.
                            </Faq>

                            <Faq title="¿Puedo ejecutar la aplicación en un PC?">
                                No, la aplicación únicamente está disponible para sistemas Android e
                                iOS. De todos modos, puedes instalar un emulador de Android y así
                                poder ejecutar la versión para Android. Hay varios emuladores
                                disponibles en el mercado, y algunos de ellos funcionan muy bien con
                                nuestra aplicación. Te recomendamos{" "}
                                <a
                                    href="https://www.bluestacks.com/es/index.html"
                                    className="link"
                                    rel="noopener noreferrer"
                                >
                                    BlueStacks
                                </a>{" "}
                                y
                                <a
                                    href="http://www.memuplay.com/"
                                    rel="noopener noreferrer"
                                    className="link"
                                >
                                    MemuPlay
                                </a>
                                .
                            </Faq>

                            <Faq title="¿Cómo se calcula la puntuación de las clasificaciones?">
                                La puntuación de las clasificaciones se basa en varios factores que
                                evalúan tu desempeño en el test. Cada respuesta correcta contribuye
                                positivamente a tu puntuación, mientras que las respuestas
                                incorrectas tienen un impacto negativo. No se penalizan las
                                respuestas en blanco. Además, se tiene en cuenta el tiempo empleado
                                en completar el test, donde se aplica una pequeña penalización por
                                tiempo extra. También consideramos el número de preguntas del test,
                                valorando positivamente la realización de tests con un mayor número
                                de preguntas. En resumen, tu puntuación final refleja tanto la
                                precisión en tus respuestas como tu eficiencia en la gestión del
                                tiempo durante el test.
                            </Faq>

                            <Faq title="Estoy haciendo tests como un loco. ¿Por qué no salgo?">
                                <strong>¡Tranquilo!</strong> Las puntuaciones en las clasificaciones
                                de cada módulo se calculan de manera dinámica, teniendo en cuenta el
                                intervalo de tiempo que elijas, el número de preguntas, los tests
                                que hayas realizado y la puntuación obtenida. Por lo tanto, si no
                                ves tu nombre en el <strong>TOP 100</strong>, podría ser porque no
                                has completado suficientes tests o tu puntuación no es lo
                                suficientemente alta.
                                <br /> <br />
                                <strong>¡No te preocupes!</strong> Sigue practicando y mejorarás tu
                                posición en la clasificación. ¡La constancia trae resultados!
                            </Faq>

                            {/*<Faq title="Estoy haciendo tests y veo que la puntuación de la aplicación no sube. ¿Está pasando algo?">
                                <strong>¡No te preocupes!</strong> La puntuación de la aplicación se{" "}
                                <strong>recalcula</strong> cada <strong>5 minutos</strong>, por lo
                                que puede que no veas tu puntuación actualizada en tiempo real. Si
                                ves que tu puntuación no sube, no te preocupes, sigue practicando y
                                verás cómo tu puntuación se actualiza en breve.
                            </Faq>*/}

                            <Faq title="¿Cómo funcionan las clasificaciones de las aplicaciones?">
                                Hemos <strong>unificado las clasificaciones</strong> de la{" "}
                                <strong>aplicación</strong> con las de la{" "}
                                <a href="https://www.iopos.academy/rankings" className="link">
                                    web
                                </a>{" "}
                                para que todo sea más sencillo. Ahora, disponemos de un único
                                ranking organizado por intervalos de tiempo: semanal, mensual y
                                anual. Las clasificaciones reflejan el rendimiento de los usuarios
                                en la semana actual, el mes actual y el año actual, respectivamente.
                                <ol className="space-y-5 list-decimal mt-2 mb-5">
                                    <li>
                                        <strong>1. Clasificación Semanal:</strong> Muestra tu
                                        posición en función de los resultados obtenidos en la semana
                                        actual. Es ideal para seguir de cerca tu progreso a corto
                                        plazo.
                                    </li>
                                    <li>
                                        <strong>2. Clasificación Mensual:</strong> Evalúa tu
                                        rendimiento durante el mes en curso, dándote una visión de
                                        tu avance a lo largo del mes.
                                    </li>
                                    <li>
                                        <strong>3. Clasificación Anual:</strong> Refleja tu
                                        desempeño acumulado en el año actual, permitiéndote ver tu
                                        progreso a largo plazo y compararlo con el de otros
                                        usuarios.
                                    </li>
                                </ol>
                                <strong>
                                    ¡Sigue practicando para mejorar tu posición y alcanzar nuevas
                                    metas!
                                </strong>
                            </Faq>
                        </div>

                        <Title>Libros</Title>
                        <Text className="text-justify">
                            En esta sección intentaremos resolver las dudas más recurrentes
                            solicitadas por nuestros usuarios. En caso que no encuentres la tuya
                            puedes contactarnos a través de nuestra sección de{" "}
                            <Link to="/contacto#form" className="default-link">
                                Contacto
                            </Link>{" "}
                            y seleccionar como asunto <i>Libros</i>.
                        </Text>

                        <div className="grid grid-cols-1 gap-5 mt-10 mb-10">
                            <Faq title="¿Cuánto tardan en enviarse los libros?">
                                Los libros se envían en el día siguiente a la compra a través del
                                servicio de envíos de Correos. Puede tardar de 2 a 3 días hábiles,
                                en condiciones normales. El envío no es certificado, es un envío
                                ordinario.
                            </Faq>
                        </div>

                        <Title>Cursos</Title>
                        <Text className="text-justify">
                            En esta sección intentaremos resolver las dudas más recurrentes
                            solicitadas por nuestros usuarios. En caso que no encuentres la tuya
                            puedes contactarnos a través de nuestra sección de{" "}
                            <Link to="/contacto#form" className="default-link">
                                Contacto
                            </Link>{" "}
                            y seleccionar como asunto <i>Cursos</i>.
                        </Text>

                        <div className="grid grid-cols-1 gap-5 mt-10 mb-10">
                            <Faq title="¿Los cursos son presenciales o online?">
                                Hay de los dos y se indicará en cada anuncio del curso. Los que sean
                                remotos o online se realizarán en nuestra{" "}
                                <a
                                    href="https://cursos.iopos.es"
                                    rel="nooponer noreferrer"
                                    target="_blank"
                                    className="default-link"
                                >
                                    Plataforma de Cursos
                                </a>
                                , y los presenciales se realizarán dependiendo del número de
                                inscritos un un sitio u en otro.
                            </Faq>

                            <Faq title="¿Puedo solicitar la devolución del importe de un curso presencial?">
                                <p>
                                    Sí, y solo si lo solicitas{" "}
                                    <strong>5 días antes del inicio del curso</strong>. En caso
                                    contrario, no será posible realizar el reembolso.
                                </p>
                                <br />
                                <p>
                                    Para ello, puedes ir a la sección de{" "}
                                    <Link to="/contacto/" className="default-link">
                                        Contacto
                                    </Link>{" "}
                                    y enviarnos un mensaje para que procedamos a realizar la
                                    devolución.
                                </p>
                            </Faq>

                            <Faq title="¿Puedo cambiar de horario o de curso una vez inscrito?">
                                <p>
                                    No se permiten cambios de horario o de curso{" "}
                                    <strong>
                                        en los últimos 5 días antes del inicio del curso
                                    </strong>
                                    , ni durante el curso.
                                </p>
                                <br />
                                <p>
                                    Si necesitas realizar un cambio, debe solicitarse con al menos 5
                                    días de antelación para que podamos reorganizar adecuadamente a
                                    los profesores.
                                </p>
                            </Faq>

                            <Faq title="¿Puedo solicitar la devolución del importe de un curso online o remoto?">
                                Sí, y solo si lo pides 5 días después de la compra del curso. En
                                caso contrario no será posible realizar el reembolso.
                                <br />
                                <br />
                                Para ello puedes ir a la sección de{" "}
                                <Link to="/contacto/" className="default-link">
                                    Contacto
                                </Link>{" "}
                                y enviarnos un mensaje para que procedamos a realizar la devolución.
                            </Faq>

                            <Faq title="¿Puedo fraccionar una compra?">
                                Sí, tenemos la opción de fraccionar compras través de nuestra
                                entidad <strong>PayPal</strong> en{" "}
                                <strong>tres pagos mensuales</strong>. Para ello, selecciona la
                                opción de <strong>"Pagar en 3 meses"</strong> en el proceso de
                                compra. Es importante tener en cuenta que PayPal no cobra intereses
                                por este servicio pero que puede no estar disponible para todos los
                                usuarios. <strong>Más información</strong> en la página de{" "}
                                <Link
                                    href="https://www.paypal.com/es/digital-wallet/ways-to-pay/buy-now-pay-later"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="text-cpurple"
                                >
                                    "Compra ahora lo que te encanta y paga después"
                                </Link>
                                .
                            </Faq>

                            <Faq title="¿No puedes fraccionar al comprar el Plan iOpos?">
                                Si este es tu caso, no te preocupes. Puedes contactar con nosotros a
                                nuestro correo de soporte{" "}
                                <Link className="text-cpurple" href="mailto:info@iopos.academy">
                                    info@iopos.academy
                                </Link>{" "}
                                y miraremos tu caso en particular.
                            </Faq>

                            <Faq title="¿Puedo comprar más de una unidad de curso para mí y otra persona?">
                                Al comprar un curso, se asigna una matrícula al usuario registrado
                                con el correo proporcionado. Si deseas comprar dos o más unidades
                                para ti y otra/s persona/s, deberás realizar compras separadas
                                utilizando los correos electrónicos de cada uno. Si tienes alguna
                                duda, no dudes en escribirnos a nuestro correo de soporte{" "}
                                <Link className="text-cpurple" href="mailto:info@iopos.academy">
                                    info@iopos.academy
                                </Link>{" "}
                                y evaluaremos tu caso en particular.
                            </Faq>

                            <Faq title="Quiero regalar un curso a otra persona. ¿Cómo puedo hacerlo?">
                                Al comprar un curso o varios cursos, debes ingresar el correo y el
                                nombre de la persona a quien deseas regalarlo. En el momento de
                                realizar el pago, puedes usar tu propio nombre y tus datos de pago.
                                Si tienes alguna duda, no dudes en escribirnos a nuestro correo de
                                soporte{" "}
                                <Link className="text-cpurple" href="mailto:info@iopos.academy">
                                    info@iopos.academy
                                </Link>{" "}
                                y evaluaremos tu caso en particular.
                            </Faq>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

const Faq = ({ title, children, startOpened, hidden }) => {
    const { warningIp } = useContext(IpContext);
    const [expanded, setExpanded] = useState(startOpened);

    return (
        <>
            {!warningIp || !hidden ? (
                <div className="rounded overflow-hidden ">
                    <div className="section-title">
                        <button
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            className="w-full flex justify-between items-center"
                        >
                            <h4 className="font-bold text-left text-sm font-montserrat">{title}</h4>

                            <div className="icon">
                                {!expanded ? (
                                    <AiOutlineArrowDown className="w-5" />
                                ) : (
                                    <AiOutlineArrowUp className="w-5" />
                                )}
                            </div>
                        </button>
                    </div>
                    <div
                        className={`overflow-hidden duration-300 ${
                            expanded ? "h-auto opacity-100" : "h-0 opacity-0"
                        }`}
                    >
                        <div className="faq-text">{children}</div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
